import React from "react"
import { useTranslation } from "react-i18next"
import { FormInstance, Tabs } from "antd"
import { FeatureAccess } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { mapOldFeatureAccessToNewFeatureAccess } from "functions/Form"
import FormCard from "components/Admin/FormCard"
import Loading from "components/Admin/Loading/Loading"
import { UserForm } from "models/user-form.model"
import FeatureAccessTab from "./FeatureAccessTab"

const { TabPane } = Tabs

export interface RightFormProps {
  form: FormInstance<UserForm>
  userFeatureAccess?: FeatureAccess[]
  saving: boolean
  loading: boolean
  companyFeatureAccess?: FeatureAccess[]
}

const RightForm: React.FC<RightFormProps> = ({
  form,
  userFeatureAccess,
  saving,
  loading,
  companyFeatureAccess
}) => {
  const { t } = useTranslation("auth")

  return (
    <FormCard testId={getTestId("user_rightform__card")}>
      <Tabs defaultActiveKey="feature_access" data-testid={getTestId("user__rightform__tabs")}>
        <TabPane tab={t("shared.feature_accesses")} key="feature_access">
          <Loading loading={loading || saving} testId={getTestId("user_rightform__loading")}>
            <FeatureAccessTab
              form={form}
              featureAccess={mapOldFeatureAccessToNewFeatureAccess(userFeatureAccess)}
              enabledFeatureAccess={companyFeatureAccess}
              loading={loading}
            />
          </Loading>
        </TabPane>
      </Tabs>
    </FormCard>
  )
}

export default RightForm
