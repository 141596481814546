import { FeatureAccess } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { TFunction } from "i18next"

export type FeatureAccessConfigurationId =
  | "alerts"
  | "businessIntelligence"
  | "dataIngestion"
  | "dataOperations"
  | "dealsPipeline"
  | "ews"
  | "favouriteComps"
  | "loans"
  | "map"
  | "moreTools"
  | "newsDatabase"
  | "plausibilityCheck"
  | "portfolioAssistant"
  | "quickSight"
  | "tenants"
  | "unitConversions"

export interface FeatureAccessConfigurationElement {
  title: string
  features: FeatureAccess[]
  id: FeatureAccessConfigurationId
}

export interface FeatureAccessConfiguration {
  alerts: FeatureAccessConfigurationElement
  businessIntelligence: FeatureAccessConfigurationElement
  dataIngestion: FeatureAccessConfigurationElement
  dataOperations: FeatureAccessConfigurationElement
  dealsPipeline: FeatureAccessConfigurationElement
  ews: FeatureAccessConfigurationElement
  favouriteComps: FeatureAccessConfigurationElement
  loans: FeatureAccessConfigurationElement
  map: FeatureAccessConfigurationElement
  moreTools: FeatureAccessConfigurationElement
  newsDatabase: FeatureAccessConfigurationElement
  plausibilityCheck: FeatureAccessConfigurationElement
  portfolioAssistant: FeatureAccessConfigurationElement
  quickSight: FeatureAccessConfigurationElement
  tenants: FeatureAccessConfigurationElement
  unitConversions: FeatureAccessConfigurationElement
}

const getConfiguration = (t: TFunction): FeatureAccessConfiguration => ({
  alerts: {
    title: t("company.features.alerts.title"),
    features: ["alerts"],
    id: "alerts"
  },
  businessIntelligence: {
    title: t("company.features.business_intelligence.title"),
    features: ["bi_portfolio_dashboard", "bi_asset_list", "bi_asset_values", "bi_rentroll"],
    id: "businessIntelligence"
  },
  dataIngestion: {
    title: t("companies.features.data_ingestion.title"),
    features: ["qp_upload_rentroll", "qp_upload_financial", "qp_upload_loan", "ie_upload_rentroll"],
    id: "dataIngestion"
  },
  dataOperations: {
    title: t("company.features.data_operations.title"),
    features: ["assets_creation", "ie_export_rentroll", "creda_rate_export"],
    id: "dataOperations"
  },
  dealsPipeline: {
    title: t("company.features.deals_pipeline.title"),
    features: ["deal_database"],
    id: "dealsPipeline"
  },
  ews: {
    title: t("companies.features.ews.title"),
    features: ["news_edge"],
    id: "ews"
  },
  favouriteComps: {
    title: t("companies.features.favourite_comps.title"),
    features: ["favourite_comps"],
    id: "favouriteComps"
  },
  loans: {
    title: t("company.features.loans.title"),
    features: ["loans_read", "loans_write", "loan_stress_test_report", "loan_sensitivity_analysis"],
    id: "loans"
  },
  map: {
    title: t("company.features.map.title"),
    features: ["market_data", "map_rent", "map_comps", "map_news"],
    id: "map"
  },
  moreTools: {
    title: t("company.features.more_tools.title"),
    features: ["datalab_database", "financial_data"],
    id: "moreTools"
  },
  newsDatabase: {
    title: t("company.features.news_database.title"),
    features: ["ma_database", "ma_newsletter"],
    id: "newsDatabase"
  },
  plausibilityCheck: {
    title: t("company.features.plausibility_check.title"),
    features: ["plausibility_check"],
    id: "plausibilityCheck"
  },
  portfolioAssistant: {
    title: t("company.features.portfolio_assistant.title"),
    features: ["portfolio_assistant"],
    id: "portfolioAssistant"
  },
  quickSight: {
    title: t("company.features.quick_sight.title"),
    features: ["quicksight_author", "quicksight"],
    id: "quickSight"
  },
  tenants: {
    title: t("companies.features.tenants_resolution.title"),
    features: ["tenants_resolution", "tenants_enrichment"],
    id: "tenants"
  },
  unitConversions: {
    title: t("companies.features.unit_conversions.title"),
    features: ["unit_conversions"],
    id: "unitConversions"
  }
})

export default getConfiguration

export const defaultSelectedValues: FeatureAccess[] = [
  "bi_portfolio_dashboard",
  "bi_asset_list",
  "bi_asset_values",
  "bi_rentroll",
  "assets_creation",
  "ie_upload_rentroll",
  "ie_export_rentroll",
  "creda_rate_export",
  "market_data",
  "map_comps",
  "map_news",
  "map_rent",
  "datalab_database",
  "financial_data"
]

export const allValues: FeatureAccess[] = defaultSelectedValues.concat([
  "alerts",
  "deal_database",
  "news_edge",
  "favourite_comps",
  "loan_sensitivity_analysis",
  "loan_stress_test_report",
  "loans_read",
  "loans_write",
  "ma_database",
  "ma_newsletter",
  "plausibility_check",
  "portfolio_assistant",
  "qp_upload_financial",
  "qp_upload_loan",
  "qp_upload_rentroll",
  "quicksight",
  "quicksight_author",
  "tenants_enrichment",
  "tenants_resolution",
  "unit_conversions"
])

export const isFeatureAccessEnabled = (
  featureAccessToCheck: FeatureAccess,
  enabledFeatures?: FeatureAccess[]
) => {
  if (!enabledFeatures) return true

  return enabledFeatures.includes(featureAccessToCheck)
}

export const hasSelectedFeaturesFromGroup = (
  selectedFeatures: FeatureAccess[] = [],
  enabledFeatureAccess: FeatureAccess[] = []
) => selectedFeatures.filter((feature) => enabledFeatureAccess.includes(feature)).length !== 0
