import React, { useContext, useEffect, useState } from "react"
import { Form } from "antd"
import useUpdateMenu from "components/Admin/hooks/useUpdateMenu"
import TwoCardForm from "components/Admin/TwoCardForm"
import { Routes } from "constants/shared"
import { CompanyContext } from "contexts/CompanyContext"
import { populateCompanyfromForm } from "functions/Form"
import { CompanyForm } from "models/company-form.model"
import { MenuKeys, SubMenuKeys } from "pages/Admin/Menu/functions"
import { useHistory, useParams } from "react-router-dom"
import { showSaveSuccessMessage } from "functions/Messages"
import { AuthContext } from "contexts/AuthContext"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { useQuery } from "@moodys/cre-cpm.hooks.use-query"
import handleApiError from "functions/Exceptions"
import Layout from "../../Layout"
import LeftForm from "./LeftForm"
import RightForm from "./RightForm"

const EditCompany: React.FC = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isSuperAdmin } = useContext(AuthContext)
  useUpdateMenu(SubMenuKeys.companies, id ? undefined : MenuKeys.newCompanies)
  const [form] = Form.useForm<CompanyForm>()
  const { createCompany, getCompany, updateCompany } = useContext(CompanyContext)
  const [companyForm, setCompanyForm] = useState<CompanyForm | undefined>()
  const [company, loadingCompany, , , , setCompany] = useQuery(() => getCompany(id), {
    disabled: !(id && isSuperAdmin())
  })
  const [, updatingCompany, , , updateCompanyRequest] = useQuery(
    () =>
      !companyForm || !company
        ? Promise.reject()
        : updateCompany(populateCompanyfromForm("PUT", companyForm, company.id)),
    {
      disabled: true,
      onSuccess: (response) => {
        showSaveSuccessMessage()
        setCompany(response.data)
      },
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const [, creatingCompany, , , createCompanyRequest] = useQuery(
    () =>
      !companyForm ? Promise.reject() : createCompany(populateCompanyfromForm("POST", companyForm)),
    {
      disabled: true,
      onSuccess: () => {
        showSaveSuccessMessage()
        history.push(Routes.admin.companies.root)
      },
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const onFinish = (values: CompanyForm) => {
    setCompanyForm(values)
  }

  useEffect(() => {
    if (companyForm) {
      if (id && company?.id) {
        updateCompanyRequest()
      } else {
        createCompanyRequest()
      }
    }
    // eslint-disable-next-line
  }, [companyForm])

  return (
    <Layout useCard={false}>
      <TwoCardForm
        form={form}
        onFinish={onFinish}
        testId={getTestId("company")}
        leftCard={
          <LeftForm
            loading={loadingCompany}
            company={company}
            id={company?.id}
            saving={updatingCompany || creatingCompany}
          />
        }
        rightCard={
          <RightForm
            form={form}
            company={company}
            saving={updatingCompany || creatingCompany}
            loading={loadingCompany}
          />
        }
      />
    </Layout>
  )
}

export default EditCompany
