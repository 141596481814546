import React, { useContext, useEffect, useState } from "react"
import { Form } from "antd"
import { MenuKeys, SubMenuKeys } from "pages/Admin/Menu/functions"
import { useHistory, useParams } from "react-router-dom"
import {
  User,
  FeatureAccess,
  Company,
  CompanyFilter,
  UserType
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import useUpdateMenu from "components/Admin/hooks/useUpdateMenu"
import TwoCardForm from "components/Admin/TwoCardForm"
import debounce from "functions/Timer"
import {
  CompanyContext,
  defaultFilters,
  defaultPagination,
  defaultSortable
} from "contexts/CompanyContext"
import { UserForm } from "models/user-form.model"
import { UserContext } from "contexts/UserContext"
import { AuthContext } from "contexts/AuthContext"
import { showSaveSuccessMessage } from "functions/Messages"
import { mapOldFeatureAccessToNewFeatureAccess, populateUserFromForm } from "functions/Form"
import { Routes } from "constants/shared"
import { useQuery } from "@moodys/cre-cpm.hooks.use-query"
import handleApiError from "functions/Exceptions"
import RightForm from "./RightForm"
import Layout from "../../Layout"
import LeftForm from "./LeftForm"

const EditUser: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  useUpdateMenu(SubMenuKeys.users, id ? undefined : MenuKeys.newUser)

  const [form] = Form.useForm<UserForm>()

  const { getUser, updateUser, createUser, resendInvitation, resetPassword } =
    useContext(UserContext)
  const {
    userType,
    isSuperAdmin,
    userCompanyUuid,
    hasLowerAdmPermissionsThan,
    isCompanyAdmin,
    hasAnyFeatureAccess
  } = useContext(AuthContext)
  const { getCompanies, getCompany } = useContext(CompanyContext)

  const [userForm, setUserForm] = useState<UserForm | undefined>()
  const [selectedCompanyUuid, setSelectedCompanyUuid] = useState<string | undefined>(
    isCompanyAdmin() ? userCompanyUuid : undefined
  )
  const [selectedRole, setSelectedRole] = useState<UserType | undefined>()

  const [userFeatureAccess, setUserFeatureAcess] = useState<FeatureAccess[] | undefined>()
  const [companyFilters, setCompanyFilters] = useState<CompanyFilter>(defaultFilters)

  const [companiesDataSource, , , , getCompaniesRequest, setCompanyDataSource] = useQuery<
    Company[] | undefined
  >(() => getCompanies(defaultPagination, companyFilters, defaultSortable), {
    disabled: true,
    onError: (err) => {
      handleApiError(err)
    }
  })

  const [selectedCompany, loadingCompany, companyFetched, , requestCompany] = useQuery(
    () => (!selectedCompanyUuid ? Promise.reject() : getCompany(selectedCompanyUuid)),
    {
      disabled: !isCompanyAdmin(),
      onError: (err) => {
        handleApiError(err)
      },
      onSuccess: (response) => {
        if (response && response.data) {
          if (isCompanyAdmin()) {
            setCompanyDataSource([response.data])
          }
          setCompanyFilters({
            ...companyFilters,
            like: response.data.attributes.name ?? ""
          })
        }
      }
    }
  )

  const [user, loadingUser, , , , setUser] = useQuery<User | undefined>(
    () => getUser(id),

    {
      disabled: !id,
      onError: (err) => {
        handleApiError(err)
      },
      onSuccess: (response) => {
        if (response?.data && hasLowerAdmPermissionsThan(response?.data)) {
          history.push(Routes.admin.users.root)
        }
        if (response?.data?.relationships?.company?.id) {
          setSelectedCompanyUuid(response.data.relationships.company.id)
        }
        setUserFeatureAcess(response?.data?.attributes?.feature_access)
        setSelectedRole(response?.data?.attributes?.user_type)
      }
    }
  )

  const [, isUpdatingUser, , , updateUserRequest] = useQuery(
    () =>
      !userForm || !user?.id || !userCompanyUuid
        ? Promise.reject()
        : updateUser(
            populateUserFromForm(userForm, userType, userCompanyUuid, hasAnyFeatureAccess, user?.id)
          ),
    {
      disabled: true,
      onSuccess: (response) => {
        if (response.data) {
          setUser(response.data)
          setUserFeatureAcess(response.data.attributes?.feature_access)
          showSaveSuccessMessage()
        }
      },
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const [, isCreatingUser, , , createUserRequest] = useQuery(
    () =>
      !userForm
        ? Promise.reject()
        : createUser(
            populateUserFromForm(userForm, userType, userCompanyUuid ?? "", hasAnyFeatureAccess)
          ),

    {
      disabled: true,
      onSuccess: () => {
        showSaveSuccessMessage()
        history.push(Routes.admin.users.root)
      },
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const [, , , , resetPasswordRequest] = useQuery(
    () => (!user?.attributes.email ? Promise.reject() : resetPassword(user.attributes.email)),

    {
      disabled: true,
      onSuccess: () => {
        showSaveSuccessMessage("shared.messages.password_reset.text")
      },
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const [, , , , resendInvitationRequest] = useQuery(() => resendInvitation(id), {
    disabled: true,
    onSuccess: () => {
      showSaveSuccessMessage("shared.messages.invitation_sent.text")
    },
    onError: (err) => {
      handleApiError(err)
    }
  })

  const onCompanySearch = debounce((value: string) => {
    setCompanyFilters({ ...companyFilters, like: value })
  })

  const onCompanyChange = (companyUuid?: string) => {
    setSelectedCompanyUuid(companyUuid)
    if (companyUuid && selectedRole === "superadmin") {
      setUserFeatureAcess(undefined)
    }
  }

  const onRoleSelected = (role: UserType) => {
    setSelectedRole(role)
  }

  useEffect(() => {
    if (selectedCompanyUuid) {
      requestCompany()
    }
    // eslint-disable-next-line
  }, [selectedCompanyUuid])

  const onSendInviteOrResetPassword = () => {
    if (user?.attributes?.active && user?.attributes.email) {
      resetPasswordRequest()
    } else {
      resendInvitationRequest()
    }
  }

  const onFinish = (values: UserForm) => {
    setUserForm(values)
  }

  useEffect(() => {
    if (userForm) {
      if (id && user?.id && userCompanyUuid) {
        updateUserRequest()
      } else {
        createUserRequest()
      }
    }
    // eslint-disable-next-line
  }, [userForm])

  useEffect(() => {
    if (isSuperAdmin() && ((id && companyFetched) || !id)) {
      getCompaniesRequest()
    }

    // eslint-disable-next-line
  }, [companyFetched, companyFilters.like])

  return (
    <Layout useCard={false}>
      <TwoCardForm
        form={form}
        onFinish={onFinish}
        testId={getTestId("user")}
        leftCard={
          <LeftForm
            loading={loadingUser || !companyFetched}
            user={user}
            id={id}
            saving={isUpdatingUser || isCreatingUser}
            form={form}
            userFeatureAccess={userFeatureAccess}
            selectedCompany={selectedCompany}
            onCompanyChange={onCompanyChange}
            onCompanySearch={onCompanySearch}
            companyDataSource={companiesDataSource}
            onSendInviteOrResetPassword={onSendInviteOrResetPassword}
            onRoleSelected={onRoleSelected}
          />
        }
        rightCard={
          <RightForm
            loading={loadingUser || loadingCompany}
            saving={isUpdatingUser || isCreatingUser}
            form={form}
            companyFeatureAccess={
              selectedCompany
                ? mapOldFeatureAccessToNewFeatureAccess(
                    selectedCompany.attributes?.feature_access ?? []
                  )
                : undefined
            }
            userFeatureAccess={userFeatureAccess}
          />
        }
      />
    </Layout>
  )
}

export default EditUser
